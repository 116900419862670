import './App.css';

import MainComponent from './header/components/main';

import ProjectsCardsComponent from './ProjectCards';

function App() {
  return (
    <div className="App">
      <MainComponent
        childElement={<ProjectsCardsComponent/>}
      />
    </div>
  );
}

export default App;
