import React from 'react';

// Internal
import {GoogleOauthStatus} from './constants';
import { MainInitialState } from './main';
import './googlelogin.css';

// External
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';

interface GoogleLoginProps {
    mainState: MainInitialState;
    onGoogleOauthSuccess: (response: CredentialResponse) => void;
    onGoogleOauthFailure: () => void;
    onGoogleOauthLogOut: () => void;
};

interface LogOutProps {
    name: string;
    photoUrl: string;
    open: boolean;
    anchorEl: HTMLElement | null;

    onOpen: (event: React.MouseEvent<HTMLElement>) => void;
    onClose: () => void;
    onLogOut: () => void;
};

const LogOutButton = (props: LogOutProps) => {
    return (
        <div
            className='google-log-out-button'
        >
            <img
                src={props.photoUrl}
                title={props.name}
                onClick={props.onOpen}
            ></img>
            
            <Menu
                anchorEl={props.anchorEl}
                id='google-log-out-menu'
                open={props.open}
                onClose={props.onClose}
                onClick={props.onClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 18,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={props.onLogOut}
                >
                    <ListItemIcon>
                        <Logout fontSize='small'/>
                    </ListItemIcon>
                    Log Out
                </MenuItem>
            </Menu>
        </div>
    )
}

const GoogleLoginComponent = (props: GoogleLoginProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        console.log("Opened")
        setAnchorEl(event?.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    return <>
        {props.mainState.googleOauthStatus===GoogleOauthStatus.LOGGED_OUT
        &&
        <GoogleLogin
            onSuccess={props.onGoogleOauthSuccess}
            onError={props.onGoogleOauthFailure}
            type='icon'
        />
        }
        {
            props.mainState.googleOauthStatus===GoogleOauthStatus.LOGGED_IN
            &&
            <LogOutButton
                name={props.mainState.name}
                photoUrl={props.mainState.photoUrl}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}

                onOpen={handleOpen}
                onClose={handleClose}
                onLogOut={props.onGoogleOauthLogOut}
            />
        }
    </>
};

export default GoogleLoginComponent;