import React, {useState} from 'react';

// Internal imports
import HeaderComponent from './header';
import { saveLoginToLocalMachine, loadLoginFromLocalMachine, clearLoginFromLocalMachine } from './login_cache';
import { GoogleOauthStatus } from './constants';
import './colors.css';

// External imports
import jwt_decode from "jwt-decode";
import {CredentialResponse} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

interface GoogleDecodedCredential {
    given_name: string;
    picture: string;
    sub: string;
}

export interface MainInitialState {
    googleOauthStatus: GoogleOauthStatus;
    name: string;
    photoUrl: string;
    userId: string;
};

interface MainComponentProps {
    childElement: React.ReactElement;
};

const MainComponent = (props: MainComponentProps) => {
    const [mainState, setMainState] = useState<MainInitialState>({
        googleOauthStatus: GoogleOauthStatus.UNINITIALIZED,
        name: '',
        photoUrl: '',
        userId: '',
    });

    React.useEffect(() => {
        const loginInfo = loadLoginFromLocalMachine();
        if (loginInfo) {
            const {name, photoUrl, userId} = loginInfo;
            setMainState({
                googleOauthStatus: GoogleOauthStatus.LOGGED_IN,
                name,
                photoUrl,
                userId,
            });
        }
        else {
            setMainState({
                googleOauthStatus: GoogleOauthStatus.LOGGED_OUT,
                name: '',
                photoUrl: '',
                userId: '',
            });
        }
    }, []);

    const googleOauthSuccess = (response: CredentialResponse) => {
        if (!response.credential) {
            console.error('Credentials missing from response', response);
            return;
        }

        const credential: string = response.credential;
        const decodedCredential: GoogleDecodedCredential = jwt_decode(credential);

        const name = decodedCredential.given_name;
        const photoUrl = decodedCredential.picture;
        const userId = decodedCredential.sub;

        setMainState({
            googleOauthStatus: GoogleOauthStatus.LOGGED_IN,
            name,
            photoUrl,
            userId,
        });
        saveLoginToLocalMachine(name, photoUrl, userId);
    };
    const googleOauthFailure = () => {
        window.alert("Google login failed!");
    };
    const googleOauthLogOut = () => {
        clearLoginFromLocalMachine();
        setMainState({
            googleOauthStatus: GoogleOauthStatus.LOGGED_OUT,
            name: '',
            photoUrl: '',
            userId: '',
        });
    }

    return <>
        <GoogleOAuthProvider clientId="660967908835-ns81b9n5j4ffbk3r70ipmf9uep5n7sh1.apps.googleusercontent.com">
            <>
            <HeaderComponent
                mainState={mainState}
                
                onGoogleOauthSuccess={googleOauthSuccess}
                onGoogleOauthFailure={googleOauthFailure}
                onGoogleOauthLogOut={googleOauthLogOut}
            />
            <div className='main-component-child-element'>
                {props.childElement}
            </div>
            </>
        </GoogleOAuthProvider>
    </>
};

export default MainComponent;