import React from 'react';

// Internal
import {MainInitialState} from './main';
import GoogleLoginComponent from './googlelogin';
import './header.css';

// External
import { CredentialResponse } from '@react-oauth/google';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

type HeaderProps = {
    mainState: MainInitialState;
    onGoogleOauthSuccess: (response: CredentialResponse) => void;
    onGoogleOauthFailure: () => void;
    onGoogleOauthLogOut: () => void;
}

const HeaderComponent = (props: HeaderProps) => {
    return (
    <AppBar
            position='static'
            className='header-component'>
        <Toolbar
            variant='dense'
        >
            <Button
                href='https://www.ericxi.com'
                sx={{ my: 2, color: 'white', display: 'block'}}
                style={{fontSize: '120%',  margin: 0}}
            >
                    ericxi.com
            </Button>
            
            <div style={{'marginLeft': 'auto'}}>
                <GoogleLoginComponent
                    mainState={props.mainState}
                    onGoogleOauthSuccess={props.onGoogleOauthSuccess}
                    onGoogleOauthFailure={props.onGoogleOauthFailure}
                    onGoogleOauthLogOut={props.onGoogleOauthLogOut}
                />
            </div>
        </Toolbar>
    </AppBar>
    );
};

export default HeaderComponent;