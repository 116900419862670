import React from 'react';

import { projectList, ProjectItem } from './projectList';
import './ProjectCards.css';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import DownloadIcon from '@mui/icons-material/Download';
import GitHubIcon from '@mui/icons-material/GitHub';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface CardItemProps {
    url: string | undefined;
};

const PhotoSection = (props: CardItemProps) => {
    return (
    <div className='photo-section'>
    {
        props.url &&
        <img
            
            src={props.url}
        >
        </img>
    }
    </div>
    );
};

interface ButtonProps extends CardItemProps {
    image: any;
    text: string;
};
const ButtonComponent = (props: ButtonProps) => {
    return (
    <>
    {
        props.url &&
        <Button
            variant='contained'
            startIcon={props.image}
            href={props.url}
        >
            {props.text}
        </Button>
    }
    </>
    );
};

const ProjectCardsComponent = () => {
    return (
    <Grid2
        container
        className='projects-component-section'
        spacing={2}
        justifyContent='center'
    >
        {
            projectList.map((projectItem: ProjectItem) => (
                <Grid2
                    style={{
                        width: '350px'
                    }}
                    xs='auto'
                >
                    <Card
                        className='project-card'
                        style={{
                            borderRadius: 0,
                        }}
                    >
                        <CardContent
                            style={{padding: 0}}
                        >
                            <PhotoSection
                                url={projectItem.photoUrl}
                            />
                            <Typography
                                variant='subtitle1'
                                style={{
                                    fontWeight: '700',
                                    padding: '5px'
                                }}
                            >
                                {projectItem.title}
                            </Typography>
                            <Typography
                                variant='body2'
                                style={{
                                    textAlign: 'left',
                                    padding: '5px'
                                }}
                            >
                                {projectItem.description}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <ButtonComponent
                                image={<PlayArrowIcon/>}
                                text='Demo'
                                url={projectItem.demoUrl}
                            />
                            <ButtonComponent
                                image={<DownloadIcon/>}
                                text='Download'
                                url={projectItem.downloadUrl}
                            />
                            <ButtonComponent
                                image={<GitHubIcon/>}
                                text='GitHub'
                                url={projectItem.githubUrl}
                            />
                        </CardActions>
                    </Card>
                </Grid2>
            ))
        }
    </Grid2>
    )
};

export default ProjectCardsComponent;