const cacheKey = 'googleOauthLoginStatus';

export const saveLoginToLocalMachine = (name: string, photoUrl: string, userId: string) => {
    localStorage.setItem(cacheKey, JSON.stringify({
        name,
        photoUrl,
        userId,
    }));
};

export const loadLoginFromLocalMachine = () => {
    const loginStatus = localStorage.getItem(cacheKey);
        if (loginStatus) {
            const parsedLoginStatus = JSON.parse(loginStatus);
            const {name, photoUrl, userId} = parsedLoginStatus;

            return {name, photoUrl, userId};
        }
        else {
            return null;
        }
}

export const clearLoginFromLocalMachine = () => {
    localStorage.removeItem(cacheKey);
}