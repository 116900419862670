export interface ProjectItem {
    title: string;
    description: string;
    photoUrl: string;
    demoUrl?: string;
    githubUrl?: string;
    downloadUrl?: string;
};

export const projectList: Array<ProjectItem> = [
    {
        title: 'Time-Lapse Creator',
        description: 'Create high-resolution time-lapse videos.',
        githubUrl: 'https://github.com/erichaixi/lapse',
        photoUrl: './timelapse.jpg',
        downloadUrl: 'https://github.com/erichaixi/lapse/raw/main/dist/TimeLapse.exe',
    },
    {
        title: 'FanDuel Lineup Manager',
        description: 'Create, optimize, and manage lineups for FanDuel Fantasy full roster contests.',
        photoUrl: './fanduel.jpg',
        demoUrl: './dfs',
    },
];